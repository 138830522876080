var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"block_in"},[_vm._m(0),_c('div',{staticClass:"box-form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"layout-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleForm)}}},[_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"name":"name","rules":{ required: true },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',[_c('h4',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input-text",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])}}],null,true)})],1),_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"name":"code","rules":{ required: true },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',[_c('h4',[_vm._v("Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"input-text",attrs:{"type":"text","name":"code"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])}}],null,true)})],1),_c('ValidationObserver',[_c('ValidationProvider',{attrs:{"name":"status","rules":{ required: true },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('h4',[_vm._v("Status")]),_c('div',{staticClass:"col-lg-4"},[_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.status),expression:"status"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.status)?_vm._i(_vm.status,null)>-1:(_vm.status)},on:{"change":function($event){var $$a=_vm.status,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.status=$$a.concat([$$v]))}else{$$i>-1&&(_vm.status=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.status=$$c}}}}),_c('span',{staticClass:"slider round"})]),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])}}],null,true)})],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',[_c('h4',[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"input-text",domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])}}],null,true)}),_c('button',{staticClass:"btn-sent",attrs:{"type":"submit"}},[_vm._v("Update")])],1)]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"title"},[_c('i',{staticClass:"fa fa-add"}),_vm._v(" Edit class")])}]

export { render, staticRenderFns }